import React from 'react'
import classNames from 'classnames'
import { StaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import _ from 'lodash'
import { Icon } from 'semantic-ui-react'

// Bowl
import rosegoldBowl from '../images/bowl-images/rosegold.jpg'
import goldBowl from '../images/bowl-images/gold.jpg'
import pearlBowl from '../images/bowl-images/pearl.jpg'
import mochaBowl from '../images/bowl-images/mocha.jpg'
import graphiteBowl from '../images/bowl-images/graphite.jpg'

// jetliner
import JetLiner from '../images/general/jet-liner.png'

const LEATHER_COLORS = ['black', 'fossil_grey', 'cappuccino', 'ivory', 'burgundy', 'dark_chocolate']

const BOWLS = [
  { color: 'rose_gold', src: rosegoldBowl },
  { color: 'gold', src: goldBowl },
  { color: 'pearl', src: pearlBowl },
  { color: 'graphite', src: graphiteBowl },
  { color: 'mocha', src: mochaBowl }
]

const NOVO_BOWLS = [
  { color: 'gold', src: goldBowl },
  { color: 'graphite', src: graphiteBowl }
]

export default ({
  model,
  displayName,
  price,
  defaultChairColor,
  defaultBowlColor,
  actionButton
}) => {
  return (
    <StaticQuery
      query={graphql`
        {
          Novoblackrosegold: file(relativePath: { eq: "spa-chairs/novo/novo-black-rosegold.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novoblackgold: file(relativePath: { eq: "spa-chairs/novo/novo-black-gold.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novoblackpearl: file(relativePath: { eq: "spa-chairs/novo/novo-black-pearl.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novoblackgraphite: file(relativePath: { eq: "spa-chairs/novo/novo-black-graphite.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novoblackmocha: file(relativePath: { eq: "spa-chairs/novo/novo-black-mocha.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novofossilgreyrosegold: file(
            relativePath: { eq: "spa-chairs/novo/novo-fossilgrey-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novofossilgreygold: file(
            relativePath: { eq: "spa-chairs/novo/novo-fossilgrey-gold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novofossilgreypearl: file(
            relativePath: { eq: "spa-chairs/novo/novo-fossilgrey-pearl.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novofossilgreygraphite: file(
            relativePath: { eq: "spa-chairs/novo/novo-fossilgrey-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novofossilgreymocha: file(
            relativePath: { eq: "spa-chairs/novo/novo-fossilgrey-mocha.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novocappuccinorosegold: file(
            relativePath: { eq: "spa-chairs/novo/novo-cappuccino-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novocappuccinogold: file(
            relativePath: { eq: "spa-chairs/novo/novo-cappuccino-gold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novocappuccinopearl: file(
            relativePath: { eq: "spa-chairs/novo/novo-cappuccino-pearl.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novocappuccinographite: file(
            relativePath: { eq: "spa-chairs/novo/novo-cappuccino-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novocappuccinomocha: file(
            relativePath: { eq: "spa-chairs/novo/novo-cappuccino-mocha.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novoivoryrosegold: file(relativePath: { eq: "spa-chairs/novo/novo-ivory-rosegold.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novoivorygold: file(relativePath: { eq: "spa-chairs/novo/novo-ivory-gold.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novoivorypearl: file(relativePath: { eq: "spa-chairs/novo/novo-ivory-pearl.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novoivorygraphite: file(relativePath: { eq: "spa-chairs/novo/novo-ivory-graphite.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novoivorymocha: file(relativePath: { eq: "spa-chairs/novo/novo-ivory-mocha.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novoburgundyrosegold: file(
            relativePath: { eq: "spa-chairs/novo/novo-burgundy-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novoburgundygold: file(relativePath: { eq: "spa-chairs/novo/novo-burgundy-gold.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novoburgundypearl: file(relativePath: { eq: "spa-chairs/novo/novo-burgundy-pearl.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novoburgundygraphite: file(
            relativePath: { eq: "spa-chairs/novo/novo-burgundy-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novoburgundymocha: file(relativePath: { eq: "spa-chairs/novo/novo-burgundy-mocha.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novodarkchocolaterosegold: file(
            relativePath: { eq: "spa-chairs/novo/novo-darkchocolate-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novodarkchocolategold: file(
            relativePath: { eq: "spa-chairs/novo/novo-darkchocolate-gold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novodarkchocolatepearl: file(
            relativePath: { eq: "spa-chairs/novo/novo-darkchocolate-pearl.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novodarkchocolategraphite: file(
            relativePath: { eq: "spa-chairs/novo/novo-darkchocolate-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novodarkchocolatemocha: file(
            relativePath: { eq: "spa-chairs/novo/novo-darkchocolate-mocha.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novoxblackrosegold: file(
            relativePath: { eq: "spa-chairs/novox/novox-black-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novoxblackgold: file(relativePath: { eq: "spa-chairs/novox/novox-black-gold.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novoxblackpearl: file(relativePath: { eq: "spa-chairs/novox/novox-black-pearl.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novoxblackgraphite: file(
            relativePath: { eq: "spa-chairs/novox/novox-black-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novoxblackmocha: file(relativePath: { eq: "spa-chairs/novox/novox-black-mocha.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novoxfossilgreyrosegold: file(
            relativePath: { eq: "spa-chairs/novox/novox-fossilgrey-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novoxfossilgreygold: file(
            relativePath: { eq: "spa-chairs/novox/novox-fossilgrey-gold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novoxfossilgreypearl: file(
            relativePath: { eq: "spa-chairs/novox/novox-fossilgrey-pearl.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novoxfossilgreygraphite: file(
            relativePath: { eq: "spa-chairs/novox/novox-fossilgrey-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novoxfossilgreymocha: file(
            relativePath: { eq: "spa-chairs/novox/novox-fossilgrey-mocha.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novoxcappuccinorosegold: file(
            relativePath: { eq: "spa-chairs/novox/novox-cappuccino-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novoxcappuccinogold: file(
            relativePath: { eq: "spa-chairs/novox/novox-cappuccino-gold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novoxcappuccinopearl: file(
            relativePath: { eq: "spa-chairs/novox/novox-cappuccino-pearl.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novoxcappuccinographite: file(
            relativePath: { eq: "spa-chairs/novox/novox-cappuccino-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novoxcappuccinomocha: file(
            relativePath: { eq: "spa-chairs/novox/novox-cappuccino-mocha.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novoxivoryrosegold: file(
            relativePath: { eq: "spa-chairs/novox/novox-ivory-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novoxivorygold: file(relativePath: { eq: "spa-chairs/novox/novox-ivory-gold.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novoxivorypearl: file(relativePath: { eq: "spa-chairs/novox/novox-ivory-pearl.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novoxivorygraphite: file(
            relativePath: { eq: "spa-chairs/novox/novox-ivory-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novoxivorymocha: file(relativePath: { eq: "spa-chairs/novox/novox-ivory-mocha.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novoxburgundyrosegold: file(
            relativePath: { eq: "spa-chairs/novox/novox-burgundy-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novoxburgundygold: file(
            relativePath: { eq: "spa-chairs/novox/novox-burgundy-gold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novoxburgundypearl: file(
            relativePath: { eq: "spa-chairs/novox/novox-burgundy-pearl.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novoxburgundygraphite: file(
            relativePath: { eq: "spa-chairs/novox/novox-burgundy-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novoxburgundymocha: file(
            relativePath: { eq: "spa-chairs/novox/novox-burgundy-mocha.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novoxdarkchocolaterosegold: file(
            relativePath: { eq: "spa-chairs/novox/novox-darkchocolate-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novoxdarkchocolategold: file(
            relativePath: { eq: "spa-chairs/novox/novox-darkchocolate-gold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novoxdarkchocolatepearl: file(
            relativePath: { eq: "spa-chairs/novox/novox-darkchocolate-pearl.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novoxdarkchocolategraphite: file(
            relativePath: { eq: "spa-chairs/novox/novox-darkchocolate-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Novoxdarkchocolatemocha: file(
            relativePath: { eq: "spa-chairs/novox/novox-darkchocolate-mocha.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Tergoblackrosegold: file(
            relativePath: { eq: "spa-chairs/tergo/tergo-black-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Tergoblackgold: file(relativePath: { eq: "spa-chairs/tergo/tergo-black-gold.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Tergoblackpearl: file(relativePath: { eq: "spa-chairs/tergo/tergo-black-pearl.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Tergoblackgraphite: file(
            relativePath: { eq: "spa-chairs/tergo/tergo-black-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Tergoblackmocha: file(relativePath: { eq: "spa-chairs/tergo/tergo-black-mocha.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Tergofossilgreyrosegold: file(
            relativePath: { eq: "spa-chairs/tergo/tergo-fossilgrey-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Tergofossilgreygold: file(
            relativePath: { eq: "spa-chairs/tergo/tergo-fossilgrey-gold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Tergofossilgreypearl: file(
            relativePath: { eq: "spa-chairs/tergo/tergo-fossilgrey-pearl.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Tergofossilgreygraphite: file(
            relativePath: { eq: "spa-chairs/tergo/tergo-fossilgrey-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Tergofossilgreymocha: file(
            relativePath: { eq: "spa-chairs/tergo/tergo-fossilgrey-mocha.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Tergocappuccinorosegold: file(
            relativePath: { eq: "spa-chairs/tergo/tergo-cappuccino-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Tergocappuccinogold: file(
            relativePath: { eq: "spa-chairs/tergo/tergo-cappuccino-gold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Tergocappuccinopearl: file(
            relativePath: { eq: "spa-chairs/tergo/tergo-cappuccino-pearl.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Tergocappuccinographite: file(
            relativePath: { eq: "spa-chairs/tergo/tergo-cappuccino-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Tergocappuccinomocha: file(
            relativePath: { eq: "spa-chairs/tergo/tergo-cappuccino-mocha.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Tergoivoryrosegold: file(
            relativePath: { eq: "spa-chairs/tergo/tergo-ivory-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Tergoivorygold: file(relativePath: { eq: "spa-chairs/tergo/tergo-ivory-gold.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Tergoivorypearl: file(relativePath: { eq: "spa-chairs/tergo/tergo-ivory-pearl.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Tergoivorygraphite: file(
            relativePath: { eq: "spa-chairs/tergo/tergo-ivory-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Tergoivorymocha: file(relativePath: { eq: "spa-chairs/tergo/tergo-ivory-mocha.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Tergoburgundyrosegold: file(
            relativePath: { eq: "spa-chairs/tergo/tergo-burgundy-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Tergoburgundygold: file(
            relativePath: { eq: "spa-chairs/tergo/tergo-burgundy-gold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Tergoburgundypearl: file(
            relativePath: { eq: "spa-chairs/tergo/tergo-burgundy-pearl.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Tergoburgundygraphite: file(
            relativePath: { eq: "spa-chairs/tergo/tergo-burgundy-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Tergoburgundymocha: file(
            relativePath: { eq: "spa-chairs/tergo/tergo-burgundy-mocha.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Tergodarkchocolaterosegold: file(
            relativePath: { eq: "spa-chairs/tergo/tergo-darkchocolate-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Tergodarkchocolategold: file(
            relativePath: { eq: "spa-chairs/tergo/tergo-darkchocolate-gold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Tergodarkchocolatepearl: file(
            relativePath: { eq: "spa-chairs/tergo/tergo-darkchocolate-pearl.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Tergodarkchocolategraphite: file(
            relativePath: { eq: "spa-chairs/tergo/tergo-darkchocolate-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Tergodarkchocolatemocha: file(
            relativePath: { eq: "spa-chairs/tergo/tergo-darkchocolate-mocha.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Magnablackrosegold: file(
            relativePath: { eq: "spa-chairs/magna/magna-black-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Magnablackgold: file(relativePath: { eq: "spa-chairs/magna/magna-black-gold.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Magnablackpearl: file(relativePath: { eq: "spa-chairs/magna/magna-black-pearl.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Magnablackgraphite: file(
            relativePath: { eq: "spa-chairs/magna/magna-black-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Magnablackmocha: file(relativePath: { eq: "spa-chairs/magna/magna-black-mocha.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Magnafossilgreyrosegold: file(
            relativePath: { eq: "spa-chairs/magna/magna-fossilgrey-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Magnafossilgreygold: file(
            relativePath: { eq: "spa-chairs/magna/magna-fossilgrey-gold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Magnafossilgreypearl: file(
            relativePath: { eq: "spa-chairs/magna/magna-fossilgrey-pearl.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Magnafossilgreygraphite: file(
            relativePath: { eq: "spa-chairs/magna/magna-fossilgrey-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Magnafossilgreymocha: file(
            relativePath: { eq: "spa-chairs/magna/magna-fossilgrey-mocha.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Magnacappuccinorosegold: file(
            relativePath: { eq: "spa-chairs/magna/magna-cappuccino-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Magnacappuccinogold: file(
            relativePath: { eq: "spa-chairs/magna/magna-cappuccino-gold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Magnacappuccinopearl: file(
            relativePath: { eq: "spa-chairs/magna/magna-cappuccino-pearl.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Magnacappuccinographite: file(
            relativePath: { eq: "spa-chairs/magna/magna-cappuccino-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Magnacappuccinomocha: file(
            relativePath: { eq: "spa-chairs/magna/magna-cappuccino-mocha.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Magnaivoryrosegold: file(
            relativePath: { eq: "spa-chairs/magna/magna-ivory-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Magnaivorygold: file(relativePath: { eq: "spa-chairs/magna/magna-ivory-gold.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Magnaivorypearl: file(relativePath: { eq: "spa-chairs/magna/magna-ivory-pearl.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Magnaivorygraphite: file(
            relativePath: { eq: "spa-chairs/magna/magna-ivory-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Magnaivorymocha: file(relativePath: { eq: "spa-chairs/magna/magna-ivory-mocha.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Magnaburgundyrosegold: file(
            relativePath: { eq: "spa-chairs/magna/magna-burgundy-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Magnaburgundygold: file(
            relativePath: { eq: "spa-chairs/magna/magna-burgundy-gold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Magnaburgundypearl: file(
            relativePath: { eq: "spa-chairs/magna/magna-burgundy-pearl.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Magnaburgundygraphite: file(
            relativePath: { eq: "spa-chairs/magna/magna-burgundy-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Magnaburgundymocha: file(
            relativePath: { eq: "spa-chairs/magna/magna-burgundy-mocha.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Magnadarkchocolaterosegold: file(
            relativePath: { eq: "spa-chairs/magna/magna-darkchocolate-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Magnadarkchocolategold: file(
            relativePath: { eq: "spa-chairs/magna/magna-darkchocolate-gold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Magnadarkchocolatepearl: file(
            relativePath: { eq: "spa-chairs/magna/magna-darkchocolate-pearl.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Magnadarkchocolategraphite: file(
            relativePath: { eq: "spa-chairs/magna/magna-darkchocolate-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Magnadarkchocolatemocha: file(
            relativePath: { eq: "spa-chairs/magna/magna-darkchocolate-mocha.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsportnovoxblackrosegold: file(
            relativePath: { eq: "spa-chairs/vsport/novox/vsport-novox-black-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsportnovoxblackgold: file(
            relativePath: { eq: "spa-chairs/vsport/novox/vsport-novox-black-gold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsportnovoxblackpearl: file(
            relativePath: { eq: "spa-chairs/vsport/novox/vsport-novox-black-pearl.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsportnovoxblackgraphite: file(
            relativePath: { eq: "spa-chairs/vsport/novox/vsport-novox-black-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsportnovoxblackmocha: file(
            relativePath: { eq: "spa-chairs/vsport/novox/vsport-novox-black-mocha.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsportnovoxfossilgreyrosegold: file(
            relativePath: { eq: "spa-chairs/vsport/novox/vsport-novox-fossilgrey-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsportnovoxfossilgreygold: file(
            relativePath: { eq: "spa-chairs/vsport/novox/vsport-novox-fossilgrey-gold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsportnovoxfossilgreypearl: file(
            relativePath: { eq: "spa-chairs/vsport/novox/vsport-novox-fossilgrey-pearl.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsportnovoxfossilgreygraphite: file(
            relativePath: { eq: "spa-chairs/vsport/novox/vsport-novox-fossilgrey-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsportnovoxfossilgreymocha: file(
            relativePath: { eq: "spa-chairs/vsport/novox/vsport-novox-fossilgrey-mocha.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsportnovoxcappuccinorosegold: file(
            relativePath: { eq: "spa-chairs/vsport/novox/vsport-novox-cappuccino-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsportnovoxcappuccinogold: file(
            relativePath: { eq: "spa-chairs/vsport/novox/vsport-novox-cappuccino-gold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsportnovoxcappuccinopearl: file(
            relativePath: { eq: "spa-chairs/vsport/novox/vsport-novox-cappuccino-pearl.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsportnovoxcappuccinographite: file(
            relativePath: { eq: "spa-chairs/vsport/novox/vsport-novox-cappuccino-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsportnovoxcappuccinomocha: file(
            relativePath: { eq: "spa-chairs/vsport/novox/vsport-novox-cappuccino-mocha.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsportnovoxivoryrosegold: file(
            relativePath: { eq: "spa-chairs/vsport/novox/vsport-novox-ivory-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsportnovoxivorygold: file(
            relativePath: { eq: "spa-chairs/vsport/novox/vsport-novox-ivory-gold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsportnovoxivorypearl: file(
            relativePath: { eq: "spa-chairs/vsport/novox/vsport-novox-ivory-pearl.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsportnovoxivorygraphite: file(
            relativePath: { eq: "spa-chairs/vsport/novox/vsport-novox-ivory-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsportnovoxivorymocha: file(
            relativePath: { eq: "spa-chairs/vsport/novox/vsport-novox-ivory-mocha.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsportnovoxburgundyrosegold: file(
            relativePath: { eq: "spa-chairs/vsport/novox/vsport-novox-burgundy-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsportnovoxburgundygold: file(
            relativePath: { eq: "spa-chairs/vsport/novox/vsport-novox-burgundy-gold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsportnovoxburgundypearl: file(
            relativePath: { eq: "spa-chairs/vsport/novox/vsport-novox-burgundy-pearl.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsportnovoxburgundygraphite: file(
            relativePath: { eq: "spa-chairs/vsport/novox/vsport-novox-burgundy-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsportnovoxburgundymocha: file(
            relativePath: { eq: "spa-chairs/vsport/novox/vsport-novox-burgundy-mocha.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsportnovoxdarkchocolaterosegold: file(
            relativePath: { eq: "spa-chairs/vsport/novox/vsport-novox-darkchocolate-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsportnovoxdarkchocolategold: file(
            relativePath: { eq: "spa-chairs/vsport/novox/vsport-novox-darkchocolate-gold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsportnovoxdarkchocolatepearl: file(
            relativePath: { eq: "spa-chairs/vsport/novox/vsport-novox-darkchocolate-pearl.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsportnovoxdarkchocolategraphite: file(
            relativePath: { eq: "spa-chairs/vsport/novox/vsport-novox-darkchocolate-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsportnovoxdarkchocolatemocha: file(
            relativePath: { eq: "spa-chairs/vsport/novox/vsport-novox-darkchocolate-mocha.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergoblackrosegold: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-black-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergoblackgold: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-black-gold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergoblackpearl: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-black-pearl.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergoblackgraphite: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-black-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergoblackmocha: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-black-mocha.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergofossilgreyrosegold: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-fossilgrey-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergofossilgreygold: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-fossilgrey-gold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergofossilgreypearl: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-fossilgrey-pearl.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergofossilgreygraphite: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-fossilgrey-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergofossilgreymocha: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-fossilgrey-mocha.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergocappuccinorosegold: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-cappuccino-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergocappuccinogold: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-cappuccino-gold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergocappuccinopearl: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-cappuccino-pearl.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergocappuccinographite: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-cappuccino-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergocappuccinomocha: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-cappuccino-mocha.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergoivoryrosegold: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-ivory-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergoivorygold: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-ivory-gold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergoivorypearl: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-ivory-pearl.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergoivorygraphite: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-ivory-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergoivorymocha: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-ivory-mocha.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergoburgundyrosegold: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-burgundy-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergoburgundygold: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-burgundy-gold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergoburgundypearl: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-burgundy-pearl.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergoburgundygraphite: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-burgundy-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergoburgundymocha: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-burgundy-mocha.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergodarkchocolaterosegold: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-darkchocolate-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergodarkchocolategold: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-darkchocolate-gold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergodarkchocolatepearl: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-darkchocolate-pearl.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergodarkchocolategraphite: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-darkchocolate-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergodarkchocolatemocha: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-darkchocolate-mocha.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergoblackrosegold: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-black-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergoblackgold: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-black-gold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergoblackpearl: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-black-pearl.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergoblackgraphite: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-black-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergoblackmocha: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-black-mocha.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergofossilgreyrosegold: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-fossilgrey-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergofossilgreygold: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-fossilgrey-gold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergofossilgreypearl: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-fossilgrey-pearl.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergofossilgreygraphite: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-fossilgrey-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergofossilgreymocha: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-fossilgrey-mocha.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergocappuccinorosegold: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-cappuccino-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergocappuccinogold: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-cappuccino-gold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergocappuccinopearl: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-cappuccino-pearl.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergocappuccinographite: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-cappuccino-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergocappuccinomocha: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-cappuccino-mocha.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergoivoryrosegold: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-ivory-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergoivorygold: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-ivory-gold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergoivorypearl: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-ivory-pearl.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergoivorygraphite: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-ivory-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergoivorymocha: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-ivory-mocha.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergoburgundyrosegold: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-burgundy-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergoburgundygold: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-burgundy-gold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergoburgundypearl: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-burgundy-pearl.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergoburgundygraphite: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-burgundy-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergoburgundymocha: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-burgundy-mocha.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergodarkchocolaterosegold: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-darkchocolate-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergodarkchocolategold: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-darkchocolate-gold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergodarkchocolatepearl: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-darkchocolate-pearl.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergodarkchocolategraphite: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-darkchocolate-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vsporttergodarkchocolatemocha: file(
            relativePath: { eq: "spa-chairs/vsport/tergo/vsport-tergo-darkchocolate-mocha.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vipblackrosegold: file(relativePath: { eq: "spa-chairs/vip/vip-black-rosegold.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vipblackgold: file(relativePath: { eq: "spa-chairs/vip/vip-black-gold.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vipblackpearl: file(relativePath: { eq: "spa-chairs/vip/vip-black-pearl.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vipblackgraphite: file(relativePath: { eq: "spa-chairs/vip/vip-black-graphite.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vipblackmocha: file(relativePath: { eq: "spa-chairs/vip/vip-black-mocha.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vipfossilgreyrosegold: file(
            relativePath: { eq: "spa-chairs/vip/vip-fossilgrey-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vipfossilgreygold: file(relativePath: { eq: "spa-chairs/vip/vip-fossilgrey-gold.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vipfossilgreypearl: file(
            relativePath: { eq: "spa-chairs/vip/vip-fossilgrey-pearl.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vipfossilgreygraphite: file(
            relativePath: { eq: "spa-chairs/vip/vip-fossilgrey-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vipfossilgreymocha: file(
            relativePath: { eq: "spa-chairs/vip/vip-fossilgrey-mocha.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vipcappuccinorosegold: file(
            relativePath: { eq: "spa-chairs/vip/vip-cappuccino-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vipcappuccinogold: file(relativePath: { eq: "spa-chairs/vip/vip-cappuccino-gold.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vipcappuccinopearl: file(
            relativePath: { eq: "spa-chairs/vip/vip-cappuccino-pearl.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vipcappuccinographite: file(
            relativePath: { eq: "spa-chairs/vip/vip-cappuccino-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vipcappuccinomocha: file(
            relativePath: { eq: "spa-chairs/vip/vip-cappuccino-mocha.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vipivoryrosegold: file(relativePath: { eq: "spa-chairs/vip/vip-ivory-rosegold.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vipivorygold: file(relativePath: { eq: "spa-chairs/vip/vip-ivory-gold.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vipivorypearl: file(relativePath: { eq: "spa-chairs/vip/vip-ivory-pearl.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vipivorygraphite: file(relativePath: { eq: "spa-chairs/vip/vip-ivory-graphite.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vipivorymocha: file(relativePath: { eq: "spa-chairs/vip/vip-ivory-mocha.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vipburgundyrosegold: file(
            relativePath: { eq: "spa-chairs/vip/vip-burgundy-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vipburgundygold: file(relativePath: { eq: "spa-chairs/vip/vip-burgundy-gold.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vipburgundypearl: file(relativePath: { eq: "spa-chairs/vip/vip-burgundy-pearl.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vipburgundygraphite: file(
            relativePath: { eq: "spa-chairs/vip/vip-burgundy-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vipburgundymocha: file(relativePath: { eq: "spa-chairs/vip/vip-burgundy-mocha.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vipdarkchocolaterosegold: file(
            relativePath: { eq: "spa-chairs/vip/vip-darkchocolate-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vipdarkchocolategold: file(
            relativePath: { eq: "spa-chairs/vip/vip-darkchocolate-gold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vipdarkchocolatepearl: file(
            relativePath: { eq: "spa-chairs/vip/vip-darkchocolate-pearl.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vipdarkchocolategraphite: file(
            relativePath: { eq: "spa-chairs/vip/vip-darkchocolate-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Vipdarkchocolatemocha: file(
            relativePath: { eq: "spa-chairs/vip/vip-darkchocolate-mocha.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticblackrosegold: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-black-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticblackgold: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-black-gold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticblackpearl: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-black-pearl.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticblackgraphite: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-black-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticblackmocha: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-black-mocha.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticfossilgreyrosegold: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-fossilgrey-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticfossilgreygold: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-fossilgrey-gold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticfossilgreypearl: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-fossilgrey-pearl.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticfossilgreygraphite: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-fossilgrey-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticfossilgreymocha: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-fossilgrey-mocha.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticcappuccinorosegold: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-cappuccino-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticcappuccinogold: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-cappuccino-gold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticcappuccinopearl: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-cappuccino-pearl.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticcappuccinographite: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-cappuccino-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticcappuccinomocha: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-cappuccino-mocha.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticivoryrosegold: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-ivory-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticivorygold: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-ivory-gold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticivorypearl: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-ivory-pearl.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticivorygraphite: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-ivory-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticivorymocha: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-ivory-mocha.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticburgundyrosegold: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-burgundy-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticburgundygold: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-burgundy-gold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticburgundypearl: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-burgundy-pearl.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticburgundygraphite: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-burgundy-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticburgundymocha: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-burgundy-mocha.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticdarkchocolaterosegold: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-darkchocolate-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticdarkchocolategold: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-darkchocolate-gold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticdarkchocolatepearl: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-darkchocolate-pearl.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticdarkchocolategraphite: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-darkchocolate-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticdarkchocolatemocha: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-darkchocolate-mocha.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticblackrosegold: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-black-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticblackgold: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-black-gold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticblackpearl: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-black-pearl.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticblackgraphite: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-black-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticblackmocha: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-black-mocha.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticfossilgreyrosegold: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-fossilgrey-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticfossilgreygold: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-fossilgrey-gold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticfossilgreypearl: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-fossilgrey-pearl.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticfossilgreygraphite: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-fossilgrey-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticfossilgreymocha: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-fossilgrey-mocha.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticcappuccinorosegold: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-cappuccino-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticcappuccinogold: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-cappuccino-gold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticcappuccinopearl: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-cappuccino-pearl.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticcappuccinographite: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-cappuccino-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticcappuccinomocha: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-cappuccino-mocha.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticivoryrosegold: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-ivory-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticivorygold: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-ivory-gold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticivorypearl: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-ivory-pearl.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticivorygraphite: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-ivory-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticivorymocha: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-ivory-mocha.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticburgundyrosegold: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-burgundy-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticburgundygold: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-burgundy-gold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticburgundypearl: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-burgundy-pearl.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticburgundygraphite: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-burgundy-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticburgundymocha: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-burgundy-mocha.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticdarkchocolaterosegold: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-darkchocolate-rosegold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticdarkchocolategold: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-darkchocolate-gold.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticdarkchocolatepearl: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-darkchocolate-pearl.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticdarkchocolategraphite: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-darkchocolate-graphite.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          Majesticdarkchocolatemocha: file(
            relativePath: { eq: "spa-chairs/majestic/majestic-darkchocolate-mocha.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
        }
      `}
      render={data => (
        <CustomChairDisplay
          data={data}
          model={model}
          displayName={displayName}
          price={price}
          defaultChairColor={defaultChairColor}
          defaultBowlColor={defaultBowlColor}
          actionButton={actionButton}
        />
      )}
    />
  )
}

const CustomChairDisplay = ({
  data,
  model,
  displayName,
  price,
  defaultChairColor,
  defaultBowlColor,
  actionButton
}) => {
  const [currentChairColor, setCurrentChairColor] = React.useState(defaultChairColor)
  const [currentBowlColor, setCurrentBowlColor] = React.useState(defaultBowlColor)

  const CHAIRS = React.useMemo(() => {
    return {
      novo: {
        black_rose_gold: data.Novoblackrosegold.childImageSharp.gatsbyImageData,
        black_gold: data.Novoblackgold.childImageSharp.gatsbyImageData,
        black_pearl: data.Novoblackpearl.childImageSharp.gatsbyImageData,
        black_graphite: data.Novoblackgraphite.childImageSharp.gatsbyImageData,
        black_mocha: data.Novoblackmocha.childImageSharp.gatsbyImageData,

        fossil_grey_rose_gold: data.Novofossilgreyrosegold.childImageSharp.gatsbyImageData,
        fossil_grey_gold: data.Novofossilgreygold.childImageSharp.gatsbyImageData,
        fossil_grey_pearl: data.Novofossilgreypearl.childImageSharp.gatsbyImageData,
        fossil_grey_graphite: data.Novofossilgreygraphite.childImageSharp.gatsbyImageData,
        fossil_grey_mocha: data.Novofossilgreymocha.childImageSharp.gatsbyImageData,

        cappuccino_rose_gold: data.Novocappuccinorosegold.childImageSharp.gatsbyImageData,
        cappuccino_gold: data.Novocappuccinogold.childImageSharp.gatsbyImageData,
        cappuccino_pearl: data.Novocappuccinopearl.childImageSharp.gatsbyImageData,
        cappuccino_graphite: data.Novocappuccinographite.childImageSharp.gatsbyImageData,
        cappuccino_mocha: data.Novocappuccinomocha.childImageSharp.gatsbyImageData,

        ivory_rose_gold: data.Novoivoryrosegold.childImageSharp.gatsbyImageData,
        ivory_gold: data.Novoivorygold.childImageSharp.gatsbyImageData,
        ivory_pearl: data.Novoivorypearl.childImageSharp.gatsbyImageData,
        ivory_graphite: data.Novoivorygraphite.childImageSharp.gatsbyImageData,
        ivory_mocha: data.Novoivorymocha.childImageSharp.gatsbyImageData,

        burgundy_rose_gold: data.Novoburgundyrosegold.childImageSharp.gatsbyImageData,
        burgundy_gold: data.Novoburgundygold.childImageSharp.gatsbyImageData,
        burgundy_pearl: data.Novoburgundypearl.childImageSharp.gatsbyImageData,
        burgundy_graphite: data.Novoburgundygraphite.childImageSharp.gatsbyImageData,
        burgundy_mocha: data.Novoburgundymocha.childImageSharp.gatsbyImageData,

        dark_chocolate_rose_gold: data.Novodarkchocolaterosegold.childImageSharp.gatsbyImageData,
        dark_chocolate_gold: data.Novodarkchocolategold.childImageSharp.gatsbyImageData,
        dark_chocolate_pearl: data.Novodarkchocolatepearl.childImageSharp.gatsbyImageData,
        dark_chocolate_graphite: data.Novodarkchocolategraphite.childImageSharp.gatsbyImageData,
        dark_chocolate_mocha: data.Novodarkchocolatemocha.childImageSharp.gatsbyImageData
      },
      novox: {
        black_rose_gold: data.Novoxblackrosegold.childImageSharp.gatsbyImageData,
        black_gold: data.Novoxblackgold.childImageSharp.gatsbyImageData,
        black_pearl: data.Novoxblackpearl.childImageSharp.gatsbyImageData,
        black_graphite: data.Novoxblackgraphite.childImageSharp.gatsbyImageData,
        black_mocha: data.Novoxblackmocha.childImageSharp.gatsbyImageData,

        fossil_grey_rose_gold: data.Novoxfossilgreyrosegold.childImageSharp.gatsbyImageData,
        fossil_grey_gold: data.Novoxfossilgreygold.childImageSharp.gatsbyImageData,
        fossil_grey_pearl: data.Novoxfossilgreypearl.childImageSharp.gatsbyImageData,
        fossil_grey_graphite: data.Novoxfossilgreygraphite.childImageSharp.gatsbyImageData,
        fossil_grey_mocha: data.Novoxfossilgreymocha.childImageSharp.gatsbyImageData,

        cappuccino_rose_gold: data.Novoxcappuccinorosegold.childImageSharp.gatsbyImageData,
        cappuccino_gold: data.Novoxcappuccinogold.childImageSharp.gatsbyImageData,
        cappuccino_pearl: data.Novoxcappuccinopearl.childImageSharp.gatsbyImageData,
        cappuccino_graphite: data.Novoxcappuccinographite.childImageSharp.gatsbyImageData,
        cappuccino_mocha: data.Novoxcappuccinomocha.childImageSharp.gatsbyImageData,

        ivory_rose_gold: data.Novoxivoryrosegold.childImageSharp.gatsbyImageData,
        ivory_gold: data.Novoxivorygold.childImageSharp.gatsbyImageData,
        ivory_pearl: data.Novoxivorypearl.childImageSharp.gatsbyImageData,
        ivory_graphite: data.Novoxivorygraphite.childImageSharp.gatsbyImageData,
        ivory_mocha: data.Novoxivorymocha.childImageSharp.gatsbyImageData,

        burgundy_rose_gold: data.Novoxburgundyrosegold.childImageSharp.gatsbyImageData,
        burgundy_gold: data.Novoxburgundygold.childImageSharp.gatsbyImageData,
        burgundy_pearl: data.Novoxburgundypearl.childImageSharp.gatsbyImageData,
        burgundy_graphite: data.Novoxburgundygraphite.childImageSharp.gatsbyImageData,
        burgundy_mocha: data.Novoxburgundymocha.childImageSharp.gatsbyImageData,

        dark_chocolate_rose_gold: data.Novoxdarkchocolaterosegold.childImageSharp.gatsbyImageData,
        dark_chocolate_gold: data.Novoxdarkchocolategold.childImageSharp.gatsbyImageData,
        dark_chocolate_pearl: data.Novoxdarkchocolatepearl.childImageSharp.gatsbyImageData,
        dark_chocolate_graphite: data.Novoxdarkchocolategraphite.childImageSharp.gatsbyImageData,
        dark_chocolate_mocha: data.Novoxdarkchocolatemocha.childImageSharp.gatsbyImageData
      },
      tergo: {
        black_rose_gold: data.Tergoblackrosegold.childImageSharp.gatsbyImageData,
        black_gold: data.Tergoblackgold.childImageSharp.gatsbyImageData,
        black_pearl: data.Tergoblackpearl.childImageSharp.gatsbyImageData,
        black_graphite: data.Tergoblackgraphite.childImageSharp.gatsbyImageData,
        black_mocha: data.Tergoblackmocha.childImageSharp.gatsbyImageData,

        fossil_grey_rose_gold: data.Tergofossilgreyrosegold.childImageSharp.gatsbyImageData,
        fossil_grey_gold: data.Tergofossilgreygold.childImageSharp.gatsbyImageData,
        fossil_grey_pearl: data.Tergofossilgreypearl.childImageSharp.gatsbyImageData,
        fossil_grey_graphite: data.Tergofossilgreygraphite.childImageSharp.gatsbyImageData,
        fossil_grey_mocha: data.Tergofossilgreymocha.childImageSharp.gatsbyImageData,

        cappuccino_rose_gold: data.Tergocappuccinorosegold.childImageSharp.gatsbyImageData,
        cappuccino_gold: data.Tergocappuccinogold.childImageSharp.gatsbyImageData,
        cappuccino_pearl: data.Tergocappuccinopearl.childImageSharp.gatsbyImageData,
        cappuccino_graphite: data.Tergocappuccinographite.childImageSharp.gatsbyImageData,
        cappuccino_mocha: data.Tergocappuccinomocha.childImageSharp.gatsbyImageData,

        ivory_rose_gold: data.Tergoivoryrosegold.childImageSharp.gatsbyImageData,
        ivory_gold: data.Tergoivorygold.childImageSharp.gatsbyImageData,
        ivory_pearl: data.Tergoivorypearl.childImageSharp.gatsbyImageData,
        ivory_graphite: data.Tergoivorygraphite.childImageSharp.gatsbyImageData,
        ivory_mocha: data.Tergoivorymocha.childImageSharp.gatsbyImageData,

        burgundy_rose_gold: data.Tergoburgundyrosegold.childImageSharp.gatsbyImageData,
        burgundy_gold: data.Tergoburgundygold.childImageSharp.gatsbyImageData,
        burgundy_pearl: data.Tergoburgundypearl.childImageSharp.gatsbyImageData,
        burgundy_graphite: data.Tergoburgundygraphite.childImageSharp.gatsbyImageData,
        burgundy_mocha: data.Tergoburgundymocha.childImageSharp.gatsbyImageData,

        dark_chocolate_rose_gold: data.Tergodarkchocolaterosegold.childImageSharp.gatsbyImageData,
        dark_chocolate_gold: data.Tergodarkchocolategold.childImageSharp.gatsbyImageData,
        dark_chocolate_pearl: data.Tergodarkchocolatepearl.childImageSharp.gatsbyImageData,
        dark_chocolate_graphite: data.Tergodarkchocolategraphite.childImageSharp.gatsbyImageData,
        dark_chocolate_mocha: data.Tergodarkchocolatemocha.childImageSharp.gatsbyImageData
      },
      magna: {
        black_rose_gold: data.Magnablackrosegold.childImageSharp.gatsbyImageData,
        black_gold: data.Magnablackgold.childImageSharp.gatsbyImageData,
        black_pearl: data.Magnablackpearl.childImageSharp.gatsbyImageData,
        black_graphite: data.Magnablackgraphite.childImageSharp.gatsbyImageData,
        black_mocha: data.Magnablackmocha.childImageSharp.gatsbyImageData,

        fossil_grey_rose_gold: data.Magnafossilgreyrosegold.childImageSharp.gatsbyImageData,
        fossil_grey_gold: data.Magnafossilgreygold.childImageSharp.gatsbyImageData,
        fossil_grey_pearl: data.Magnafossilgreypearl.childImageSharp.gatsbyImageData,
        fossil_grey_graphite: data.Magnafossilgreygraphite.childImageSharp.gatsbyImageData,
        fossil_grey_mocha: data.Magnafossilgreymocha.childImageSharp.gatsbyImageData,

        cappuccino_rose_gold: data.Magnacappuccinorosegold.childImageSharp.gatsbyImageData,
        cappuccino_gold: data.Magnacappuccinogold.childImageSharp.gatsbyImageData,
        cappuccino_pearl: data.Magnacappuccinopearl.childImageSharp.gatsbyImageData,
        cappuccino_graphite: data.Magnacappuccinographite.childImageSharp.gatsbyImageData,
        cappuccino_mocha: data.Magnacappuccinomocha.childImageSharp.gatsbyImageData,

        ivory_rose_gold: data.Magnaivoryrosegold.childImageSharp.gatsbyImageData,
        ivory_gold: data.Magnaivorygold.childImageSharp.gatsbyImageData,
        ivory_pearl: data.Magnaivorypearl.childImageSharp.gatsbyImageData,
        ivory_graphite: data.Magnaivorygraphite.childImageSharp.gatsbyImageData,
        ivory_mocha: data.Magnaivorymocha.childImageSharp.gatsbyImageData,

        burgundy_rose_gold: data.Magnaburgundyrosegold.childImageSharp.gatsbyImageData,
        burgundy_gold: data.Magnaburgundygold.childImageSharp.gatsbyImageData,
        burgundy_pearl: data.Magnaburgundypearl.childImageSharp.gatsbyImageData,
        burgundy_graphite: data.Magnaburgundygraphite.childImageSharp.gatsbyImageData,
        burgundy_mocha: data.Magnaburgundymocha.childImageSharp.gatsbyImageData,

        dark_chocolate_rose_gold: data.Magnadarkchocolaterosegold.childImageSharp.gatsbyImageData,
        dark_chocolate_gold: data.Magnadarkchocolategold.childImageSharp.gatsbyImageData,
        dark_chocolate_pearl: data.Magnadarkchocolatepearl.childImageSharp.gatsbyImageData,
        dark_chocolate_graphite: data.Magnadarkchocolategraphite.childImageSharp.gatsbyImageData,
        dark_chocolate_mocha: data.Magnadarkchocolatemocha.childImageSharp.gatsbyImageData
      },
      vip: {
        black_rose_gold: data.Vipblackrosegold.childImageSharp.gatsbyImageData,
        black_gold: data.Vipblackgold.childImageSharp.gatsbyImageData,
        black_pearl: data.Vipblackpearl.childImageSharp.gatsbyImageData,
        black_graphite: data.Vipblackgraphite.childImageSharp.gatsbyImageData,
        black_mocha: data.Vipblackmocha.childImageSharp.gatsbyImageData,

        fossil_grey_rose_gold: data.Vipfossilgreyrosegold.childImageSharp.gatsbyImageData,
        fossil_grey_gold: data.Vipfossilgreygold.childImageSharp.gatsbyImageData,
        fossil_grey_pearl: data.Vipfossilgreypearl.childImageSharp.gatsbyImageData,
        fossil_grey_graphite: data.Vipfossilgreygraphite.childImageSharp.gatsbyImageData,
        fossil_grey_mocha: data.Vipfossilgreymocha.childImageSharp.gatsbyImageData,

        cappuccino_rose_gold: data.Vipcappuccinorosegold.childImageSharp.gatsbyImageData,
        cappuccino_gold: data.Vipcappuccinogold.childImageSharp.gatsbyImageData,
        cappuccino_pearl: data.Vipcappuccinopearl.childImageSharp.gatsbyImageData,
        cappuccino_graphite: data.Vipcappuccinographite.childImageSharp.gatsbyImageData,
        cappuccino_mocha: data.Vipcappuccinomocha.childImageSharp.gatsbyImageData,

        ivory_rose_gold: data.Vipivoryrosegold.childImageSharp.gatsbyImageData,
        ivory_gold: data.Vipivorygold.childImageSharp.gatsbyImageData,
        ivory_pearl: data.Vipivorypearl.childImageSharp.gatsbyImageData,
        ivory_graphite: data.Vipivorygraphite.childImageSharp.gatsbyImageData,
        ivory_mocha: data.Vipivorymocha.childImageSharp.gatsbyImageData,

        burgundy_rose_gold: data.Vipburgundyrosegold.childImageSharp.gatsbyImageData,
        burgundy_gold: data.Vipburgundygold.childImageSharp.gatsbyImageData,
        burgundy_pearl: data.Vipburgundypearl.childImageSharp.gatsbyImageData,
        burgundy_graphite: data.Vipburgundygraphite.childImageSharp.gatsbyImageData,
        burgundy_mocha: data.Vipburgundymocha.childImageSharp.gatsbyImageData,

        dark_chocolate_rose_gold: data.Vipdarkchocolaterosegold.childImageSharp.gatsbyImageData,
        dark_chocolate_gold: data.Vipdarkchocolategold.childImageSharp.gatsbyImageData,
        dark_chocolate_pearl: data.Vipdarkchocolatepearl.childImageSharp.gatsbyImageData,
        dark_chocolate_graphite: data.Vipdarkchocolategraphite.childImageSharp.gatsbyImageData,
        dark_chocolate_mocha: data.Vipdarkchocolatemocha.childImageSharp.gatsbyImageData
      },
      vsportn: {
        black_rose_gold: data.Vsportnovoxblackrosegold.childImageSharp.gatsbyImageData,
        black_gold: data.Vsportnovoxblackgold.childImageSharp.gatsbyImageData,
        black_pearl: data.Vsportnovoxblackpearl.childImageSharp.gatsbyImageData,
        black_graphite: data.Vsportnovoxblackgraphite.childImageSharp.gatsbyImageData,
        black_mocha: data.Vsportnovoxblackmocha.childImageSharp.gatsbyImageData,

        fossil_grey_rose_gold: data.Vsportnovoxfossilgreyrosegold.childImageSharp.gatsbyImageData,
        fossil_grey_gold: data.Vsportnovoxfossilgreygold.childImageSharp.gatsbyImageData,
        fossil_grey_pearl: data.Vsportnovoxfossilgreypearl.childImageSharp.gatsbyImageData,
        fossil_grey_graphite: data.Vsportnovoxfossilgreygraphite.childImageSharp.gatsbyImageData,
        fossil_grey_mocha: data.Vsportnovoxfossilgreymocha.childImageSharp.gatsbyImageData,

        cappuccino_rose_gold: data.Vsportnovoxcappuccinorosegold.childImageSharp.gatsbyImageData,
        cappuccino_gold: data.Vsportnovoxcappuccinogold.childImageSharp.gatsbyImageData,
        cappuccino_pearl: data.Vsportnovoxcappuccinopearl.childImageSharp.gatsbyImageData,
        cappuccino_graphite: data.Vsportnovoxcappuccinographite.childImageSharp.gatsbyImageData,
        cappuccino_mocha: data.Vsportnovoxcappuccinomocha.childImageSharp.gatsbyImageData,

        ivory_rose_gold: data.Vsportnovoxivoryrosegold.childImageSharp.gatsbyImageData,
        ivory_gold: data.Vsportnovoxivorygold.childImageSharp.gatsbyImageData,
        ivory_pearl: data.Vsportnovoxivorypearl.childImageSharp.gatsbyImageData,
        ivory_graphite: data.Vsportnovoxivorygraphite.childImageSharp.gatsbyImageData,
        ivory_mocha: data.Vsportnovoxivorymocha.childImageSharp.gatsbyImageData,

        burgundy_rose_gold: data.Vsportnovoxburgundyrosegold.childImageSharp.gatsbyImageData,
        burgundy_gold: data.Vsportnovoxburgundygold.childImageSharp.gatsbyImageData,
        burgundy_pearl: data.Vsportnovoxburgundypearl.childImageSharp.gatsbyImageData,
        burgundy_graphite: data.Vsportnovoxburgundygraphite.childImageSharp.gatsbyImageData,
        burgundy_mocha: data.Vsportnovoxburgundymocha.childImageSharp.gatsbyImageData,

        dark_chocolate_rose_gold:
          data.Vsportnovoxdarkchocolaterosegold.childImageSharp.gatsbyImageData,
        dark_chocolate_gold: data.Vsportnovoxdarkchocolategold.childImageSharp.gatsbyImageData,
        dark_chocolate_pearl: data.Vsportnovoxdarkchocolatepearl.childImageSharp.gatsbyImageData,
        dark_chocolate_graphite:
          data.Vsportnovoxdarkchocolategraphite.childImageSharp.gatsbyImageData,
        dark_chocolate_mocha: data.Vsportnovoxdarkchocolatemocha.childImageSharp.gatsbyImageData
      },
      vsportt: {
        black_rose_gold: data.Vsporttergoblackrosegold.childImageSharp.gatsbyImageData,
        black_gold: data.Vsporttergoblackgold.childImageSharp.gatsbyImageData,
        black_pearl: data.Vsporttergoblackpearl.childImageSharp.gatsbyImageData,
        black_graphite: data.Vsporttergoblackgraphite.childImageSharp.gatsbyImageData,
        black_mocha: data.Vsporttergoblackmocha.childImageSharp.gatsbyImageData,

        fossil_grey_rose_gold: data.Vsporttergofossilgreyrosegold.childImageSharp.gatsbyImageData,
        fossil_grey_gold: data.Vsporttergofossilgreygold.childImageSharp.gatsbyImageData,
        fossil_grey_pearl: data.Vsporttergofossilgreypearl.childImageSharp.gatsbyImageData,
        fossil_grey_graphite: data.Vsporttergofossilgreygraphite.childImageSharp.gatsbyImageData,
        fossil_grey_mocha: data.Vsporttergofossilgreymocha.childImageSharp.gatsbyImageData,

        cappuccino_rose_gold: data.Vsporttergocappuccinorosegold.childImageSharp.gatsbyImageData,
        cappuccino_gold: data.Vsporttergocappuccinogold.childImageSharp.gatsbyImageData,
        cappuccino_pearl: data.Vsporttergocappuccinopearl.childImageSharp.gatsbyImageData,
        cappuccino_graphite: data.Vsporttergocappuccinographite.childImageSharp.gatsbyImageData,
        cappuccino_mocha: data.Vsporttergocappuccinomocha.childImageSharp.gatsbyImageData,

        ivory_rose_gold: data.Vsporttergoivoryrosegold.childImageSharp.gatsbyImageData,
        ivory_gold: data.Vsporttergoivorygold.childImageSharp.gatsbyImageData,
        ivory_pearl: data.Vsporttergoivorypearl.childImageSharp.gatsbyImageData,
        ivory_graphite: data.Vsporttergoivorygraphite.childImageSharp.gatsbyImageData,
        ivory_mocha: data.Vsporttergoivorymocha.childImageSharp.gatsbyImageData,

        burgundy_rose_gold: data.Vsporttergoburgundyrosegold.childImageSharp.gatsbyImageData,
        burgundy_gold: data.Vsporttergoburgundygold.childImageSharp.gatsbyImageData,
        burgundy_pearl: data.Vsporttergoburgundypearl.childImageSharp.gatsbyImageData,
        burgundy_graphite: data.Vsporttergoburgundygraphite.childImageSharp.gatsbyImageData,
        burgundy_mocha: data.Vsporttergoburgundymocha.childImageSharp.gatsbyImageData,

        dark_chocolate_rose_gold:
          data.Vsporttergodarkchocolaterosegold.childImageSharp.gatsbyImageData,
        dark_chocolate_gold: data.Vsporttergodarkchocolategold.childImageSharp.gatsbyImageData,
        dark_chocolate_pearl: data.Vsporttergodarkchocolatepearl.childImageSharp.gatsbyImageData,
        dark_chocolate_graphite:
          data.Vsporttergodarkchocolategraphite.childImageSharp.gatsbyImageData,
        dark_chocolate_mocha: data.Vsporttergodarkchocolatemocha.childImageSharp.gatsbyImageData
      },
      majestic: {
        black_rose_gold: data.Majesticblackrosegold.childImageSharp.gatsbyImageData,
        black_gold: data.Majesticblackgold.childImageSharp.gatsbyImageData,
        black_pearl: data.Majesticblackpearl.childImageSharp.gatsbyImageData,
        black_graphite: data.Majesticblackgraphite.childImageSharp.gatsbyImageData,
        black_mocha: data.Majesticblackmocha.childImageSharp.gatsbyImageData,

        fossil_grey_rose_gold: data.Majesticfossilgreyrosegold.childImageSharp.gatsbyImageData,
        fossil_grey_gold: data.Majesticfossilgreygold.childImageSharp.gatsbyImageData,
        fossil_grey_pearl: data.Majesticfossilgreypearl.childImageSharp.gatsbyImageData,
        fossil_grey_graphite: data.Majesticfossilgreygraphite.childImageSharp.gatsbyImageData,
        fossil_grey_mocha: data.Majesticfossilgreymocha.childImageSharp.gatsbyImageData,

        cappuccino_rose_gold: data.Majesticcappuccinorosegold.childImageSharp.gatsbyImageData,
        cappuccino_gold: data.Majesticcappuccinogold.childImageSharp.gatsbyImageData,
        cappuccino_pearl: data.Majesticcappuccinopearl.childImageSharp.gatsbyImageData,
        cappuccino_graphite: data.Majesticcappuccinographite.childImageSharp.gatsbyImageData,
        cappuccino_mocha: data.Majesticcappuccinomocha.childImageSharp.gatsbyImageData,

        ivory_rose_gold: data.Majesticivoryrosegold.childImageSharp.gatsbyImageData,
        ivory_gold: data.Majesticivorygold.childImageSharp.gatsbyImageData,
        ivory_pearl: data.Majesticivorypearl.childImageSharp.gatsbyImageData,
        ivory_graphite: data.Majesticivorygraphite.childImageSharp.gatsbyImageData,
        ivory_mocha: data.Majesticivorymocha.childImageSharp.gatsbyImageData,

        burgundy_rose_gold: data.Majesticburgundyrosegold.childImageSharp.gatsbyImageData,
        burgundy_gold: data.Majesticburgundygold.childImageSharp.gatsbyImageData,
        burgundy_pearl: data.Majesticburgundypearl.childImageSharp.gatsbyImageData,
        burgundy_graphite: data.Majesticburgundygraphite.childImageSharp.gatsbyImageData,
        burgundy_mocha: data.Majesticburgundymocha.childImageSharp.gatsbyImageData,

        dark_chocolate_rose_gold:
          data.Majesticdarkchocolaterosegold.childImageSharp.gatsbyImageData,
        dark_chocolate_gold: data.Majesticdarkchocolategold.childImageSharp.gatsbyImageData,
        dark_chocolate_pearl: data.Majesticdarkchocolatepearl.childImageSharp.gatsbyImageData,
        dark_chocolate_graphite: data.Majesticdarkchocolategraphite.childImageSharp.gatsbyImageData,
        dark_chocolate_mocha: data.Majesticdarkchocolatemocha.childImageSharp.gatsbyImageData
      }
    }
  }, [data])

  return (
    <div className="row shadow-sm py-4">
      <div className="col-lg-6 d-flex flex-column align-items-center animated bounceInLeft slow px-5">
        <GatsbyImage
          image={CHAIRS[`${model}`][`${currentChairColor}_${currentBowlColor}`]}
          style={{
            width: '100%',
            maxWidth: '400px',
            height: 'auto'
          }}
          className="animated bounceIn slow"
          alt=""
        />
        <h6 className="text-secondary">Digital Coloring May Vary</h6>
      </div>

      <div className="col-lg-6 d-flex flex-column justify-content-center animated bounceInRight slow py-4">
        <div className="d-flex flex-wrap align-items-center">
          <h5 className="m-0 pr-2">
            <strong>Chair </strong>
          </h5>
          {LEATHER_COLORS.map(chairColor => {
            return (
              <button
                aria-label="chair color button"
                type="button"
                key={chairColor}
                onClick={() => {
                  setCurrentChairColor(chairColor)
                }}
                className={classNames(`${chairColor}`, 'rounded-circle ml-2 color-btn', {
                  'animated infinite pulse': currentChairColor === chairColor
                })}
              />
            )
          })}
        </div>

        <div className="d-flex flex-wrap align-items-center">
          <h5 className="m-0">
            <strong>Bowl </strong>
          </h5>

          <BowlColorSelection
            BOWLS={model === 'novo' ? NOVO_BOWLS : BOWLS}
            setCurrentBowlColor={setCurrentBowlColor}
            currentBowlColor={currentBowlColor}
          />
        </div>

        <div className="w-100 mt-2">
          <h1 className="text-uppercase my-0">
            {displayName}{' '}
            <p>
              {_.upperCase(currentChairColor)} LEATHER - {_.upperCase(currentBowlColor)} BOWL
            </p>
          </h1>

          <h1 className="mt-0 text-secondary">$ {price}</h1>

          <p style={{ marging: '.5rem' }}>
            Any spa chair purchase will come with a pedi stool color of your choice
          </p>

          <div className="row">
            <div className="col">
              <a href="tel:7148958865" className="action-button py-2 px-4 rounded-pill">
                CALL FOR QUOTE
              </a>

              {actionButton && (
                <Link
                  className="action-button-basic mx-2 rounded-pill"
                  to={`/pedicure-spa-chairs/${model.includes('vsport') ? 'vsport' : model}`}
                >
                  <Icon name="arrow right" /> Learn more
                </Link>
              )}
            </div>
          </div>

          <br />
          <div className="row py-4">
            <div className="col-lg-4">
              <img src={JetLiner} alt="jet liner" width="250" height="250" />
            </div>

            <div className="col-lg-8 d-flex px-4d flex-column justify-content-center">
              <h2>
                DISPOSABLE JET{' '}
                <p>
                  <strong className="text-secondary">(Includes Attached Liner)</strong>
                </p>
              </h2>

              <h3 className="text-secondary mt-0">
                $0.55/Piece <strong className="text-dark">or</strong> $110/Box (200 Pieces)
              </h3>
              <p>
                <strong className="text-secondary">100% Disposable Jet</strong> is a Contégo
                patented technology which <strong>PREVENTS CROSS-CONTAMINATION</strong> while still
                maintaining <strong>a SENSATIONAL FOOT MASSAGE</strong>
              </p>
            </div>
          </div>
        </div>
        <br />
      </div>
    </div>
  )
}

const BowlColorSelection = ({ BOWLS, setCurrentBowlColor, currentBowlColor }) => {
  return (
    <>
      {BOWLS.map(bowl => {
        return (
          <div
            key={bowl.color}
            tabIndex="0"
            role="button"
            className="button"
            onClick={() => setCurrentBowlColor(bowl.color)}
            onKeyPress={e => {
              if (e.key === 'enter') {
                setCurrentBowlColor(bowl.color)
              }
            }}
          >
            <img
              src={bowl.src}
              alt={`${bowl.color} bowl`}
              className={classNames('rounded-circle ml-2 my-1 bowl-img', {
                'animated infinite pulse': currentBowlColor === bowl.color
              })}
            />
          </div>
        )
      })}
    </>
  )
}
